import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import ownerMenu from 'menu-items/ownerMenu';
//import adminMenu from 'menu-items/adminMenu';
import salesExecutiveMenu from 'menu-items/salesExecutiveMenu';
import officeStaffMenu from 'menu-items/officeStaffMenu';
import serviceEngineerMenu from 'menu-items/serviceEngineerMenu';
import accountsMenu from 'menu-items/accountsMenu';
import adminMenu from 'menu-items/adminMenu';
import { useEffect, useState } from 'react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { role, locationId } = useSelector((state) => state.userReducer);
    console.log('role', role, locationId, adminMenu);
    // const role = 1;
    const [adminMenuItems, setAdminMenuItems] = useState([]);
    useEffect(() => {
        if (role === 2) {
            let currentAdminMenu = JSON.parse(JSON.stringify(adminMenu));

            if (locationId !== 2) {
                const adminMenuGroup = currentAdminMenu[0];
                if (adminMenuGroup?.children) {
                    const transactionsIndex = adminMenuGroup.children?.findIndex((ele) => ele.id === 'transactions');

                    if (transactionsIndex !== -1) {
                        const transactionsSection = adminMenuGroup.children[transactionsIndex];
                        if (transactionsSection.children) {
                            const filteredChildren = transactionsSection.children?.filter((item) => item.id !== 'transfer-records');
                            transactionsSection.children = filteredChildren;
                            adminMenuGroup.children[transactionsIndex] = transactionsSection;
                        }
                    }
                }
            }
            setAdminMenuItems(currentAdminMenu);
        }
    }, [role, locationId]);

    let menuItemsTobAdded = [];
    if (role == 1) {
        menuItemsTobAdded = ownerMenu;
    } else if (role == 2) {
        menuItemsTobAdded = adminMenuItems;
    } else if (role == 3) {
        menuItemsTobAdded = salesExecutiveMenu;
    } else if (role == 4) {
        menuItemsTobAdded = officeStaffMenu;
    } else if (role == 5) {
        menuItemsTobAdded = serviceEngineerMenu;
    } else if (role == 7) {
        menuItemsTobAdded = accountsMenu;
    }
    // menuItemsTobAdded = adminMenuItems;
    console.log('menuItems', menuItemsTobAdded);
    const navItems = menuItemsTobAdded
        // .filter((item) => item?.id == 'transfer-records')
        .map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return <>{navItems}</>;
};

export default MenuList;
